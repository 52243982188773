export const CLUSTER_STYLES = [
  {
    textColor: 'white',
    url: '/svg/location-cluster.svg',
    height: 32,
    width: 32,
    textSize: 14,
    fontWeight: 'bold',
    anchorText: [7, 0],
  },
];
